<script setup lang="ts">
import type { ApiError, ValidationErrorResponse } from "~/types/apiResponses"

import { useAuthStore } from "~/stores/auth"
import { getValidationMessage } from "~/utils/errorHandling"

definePageMeta({
  layout: "auth",
  auth: "guest",
})

useHead({
  title: "Login",
})

const authStore = useAuthStore()

const nextPage = useRoute().query.next?.toString()

const identifier = ref<string>()
const password = ref<string>()
const ephemeral = ref<boolean>(false)
const error = ref<string>()
const validationErrors = ref<ValidationErrorResponse>()

async function login() {
  error.value = undefined
  try {
    await authStore.login(identifier.value, password.value, ephemeral.value)
    if (authStore.isLoggedIn) {
      navigateTo(nextPage ?? "/")
    }
  } catch (e: any) {
    if (e.name === "FetchError") {
      const apiError = e as ApiError
      if (apiError.data?.status === "error") {
        error.value = apiError.data.message
        return
      } else if (apiError.data?.status === "validationError") {
        validationErrors.value = apiError.data
        return
      }
    }

    error.value = "An error occurred while logging in"
    console.error("Failed login request: ", e)
  }
}
</script>

<template>
  <form
    class="mx-4 flex w-full flex-1 flex-col items-center justify-center gap-6 space-y-2 border border-dusk-200 bg-white px-8 py-10 shadow-sm md:h-auto md:max-w-md md:flex-initial md:rounded-xl"
    @submit.prevent="login">
    <div class="space-y-1">
      <Wordmark class="text-center text-forest-500" />
      <h1
        class="text-center font-heading text-section font-bold text-ebony-700">
        Login into your Deimos Account
      </h1>
    </div>
    <InputBox
      v-model="identifier"
      class="self-stretch"
      label="Email"
      autocomplete="email"
      placeholder="someone@example.com"
      :validation-error="
        getValidationMessage(validationErrors, 'identifier')
      " />
    <InputBox
      v-model="password"
      class="self-stretch"
      type="password"
      label="Password"
      :placeholder="'&#9679;'.repeat(8)"
      autocomplete="current-password"
      :validation-error="getValidationMessage(validationErrors, 'password')" />
    <div class="self-start">
      <InputToggle
        v-model="ephemeral"
        label="This is a public or shared computer"
        help-text="If this computer isn't a trusted computer, select this to be logged out when you close your browser session" />
    </div>
    <FormError v-if="error" :error="error" />
    <FormControlWrapper compact alignment="center">
      <FormSubmitControl class="w-80">Login</FormSubmitControl>
      <i18n-t class="text-dusk-500" keypath="login.forgot" tag="p">
        <template #reset>
          <InlineLink to="/forgot">{{ $t("login.reset") }}</InlineLink>
        </template>
      </i18n-t>
    </FormControlWrapper>
  </form>
</template>
